import React, { useEffect, useState } from 'react';
import { Stack, Typography, IconButton, CircularProgress } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineOppositeContent, timelineOppositeContentClasses, TimelineContent } from '@mui/lab';
import { motion } from 'framer-motion';
import { Edit, Delete } from '@mui/icons-material';
import { getAcademicExperienceById } from '../../services/academicExperienceServices';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../auth/AuthProvider';

const AcademicAppointmentsPage = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const { userId } = useParams();
  const { token } = useUserContext();

  useEffect(() => {
    const fetchAppointments = async () => {
      setLoading(true);
      try {
        const response = await getAcademicExperienceById(userId, "Academic Appointment", token);
        setAppointments(response); // Assume response is the appointments array
      } catch (error) {
        console.error("Failed to fetch appointments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, [userId, token]);

  if (loading) {
    return (
      <Stack alignItems="center" justifyContent="center" >
        <CircularProgress />
      </Stack>
    );
  }
  const sliceTre = (string) => {
    return string?.split("-")[1]?.toLowerCase() || "";
  };
  return (
    <Stack alignItems="start" sx={{ width: '100%', p: 2 }}>
      {appointments.length === 0 ? (
        <Typography variant="h6" color="text.secondary" align="center">
          No academic appointments have been added yet.
        </Typography>
      ) : (
        <Timeline
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.3,
            },
          }}
        >
          {appointments.map((appointment, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: index * 0.3 }} // Animated entrance
            >
              <TimelineItem>
                <TimelineOppositeContent color="text.secondary" sx={{ typography: 'body2', lineHeight: 1.5 }}>
                  {`${appointment.startDate} - ${appointment.endDate == null ? "present" : appointment.endDate}`}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary" sx={{ transform: 'scale(1.2)' }} />
                  {index < appointments.length - 1 && <TimelineConnector sx={{ backgroundColor: '#1976d2' }} />}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant='h5' component="span" sx={{ fontWeight: 600 }} color="primary">
                    {appointment.academicAppointment} in {appointment.fieldOfStudy}
                  </Typography>
                  <Typography variant="h6">
                    {appointment.institution}, {`Fac. of ${appointment.faculty}, Dept. of ${appointment.department}`}
                  </Typography>
                  <Stack direction="row" alignItems="center">
                    <img
                      style={{ marginRight: 6 }}
                      loading="lazy"
                      srcSet={`https://flagcdn.com/w40/${sliceTre(appointment.country)}.png 2x`}
                      src={`https://flagcdn.com/w20/${sliceTre(appointment.country)}.png`}
                      alt=""
                    />
                    <Typography variant="body2" color="text.secondary">
                      {appointment.country}
                    </Typography>
                  </Stack>
                </TimelineContent>
              </TimelineItem>
            </motion.div>
          ))}
        </Timeline>
      )}
    </Stack>
  );
};

export default AcademicAppointmentsPage;
