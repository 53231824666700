import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Ensure axios is installed
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Container, Grid, IconButton, Paper, Stack, TextField, Button, Typography, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import { TimelineOppositeContent, timelineOppositeContentClasses } from '@mui/lab';
import { Edit, Save, Cancel, ArrowBack, Delete, Add, Work } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getAcademicExperienceForCurrentUser, updateAcademicAppointment, deleteAcademicExperience, createAcademicAppointment } from '../services/academicExperienceServices'; // Update this line
import { useUserContext } from '../auth/AuthProvider';
import CountrySelect from '../components/CountrySelect';
import { motion } from "framer-motion";
import UniversitySearch from '../components/profile/UniversitySearch';
import DepartmentSearch from '../components/profile/DepartmentSearch';
import FieldOfStudySearch from '../components/profile/FieldOfStudySearch';

const AcademicAppointmentsPage = () => {
  const navigate = useNavigate();
  const { token } = useUserContext();
  const [appointments, setAppointments] = useState([]);
  const [formValues, setFormValues] = useState({
    startDate: '',
    endDate: '',
    institution: '',
    faculty: '',
    department: '',
    fieldOfStudy: '', // Changed from 'position' to 'fieldOfStudy'
    country: '',
    academicAppointment: '',
  });
  const [isPresent, setIsPresent] = useState(false); // New state for checkbox
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [valid, setValid] = useState(false);
  const handleSelectUniversity = (newValue) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      institution: newValue,
    }));
  };
  useEffect(() => {
    const { startDate, institution, faculty, department, fieldOfStudy, country, academicAppointment } = formValues;
    setValid(startDate && institution && faculty && department && fieldOfStudy && country && academicAppointment); // Updated validation
  }, [formValues]);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const data = await getAcademicExperienceForCurrentUser("Academic Appointment", token);
        console.log("data", data)
        setAppointments(data);
      } catch (error) {
        console.error('Failed to fetch appointments:', error);
      }
    };

    fetchAppointments();
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleAddAppointment = () => {
    setIsFormVisible(true);
    setEditingIndex(null);
    resetForm();
  };

  const resetForm = () => {
    setFormValues({
      startDate: '',
      endDate: '',
      institution: '',
      faculty: '',
      department: '',
      fieldOfStudy: '', // Reset fieldOfStudy
      country: '',
      academicAppointment: '',
    });
    setIsPresent(false);
  };

  const handleDeleteAppointment = async (index) => {
    const appointmentId = appointments[index].id;
    try {
      await deleteAcademicExperience(appointmentId, token);
      setAppointments((prevAppointments) => prevAppointments.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Failed to delete appointment:", error);
    }
  };

  const handleSave = async () => {
    if (editingIndex !== null) {
      try {
        const appointmentId = appointments[editingIndex].id;
        await updateAcademicAppointment(appointmentId, formValues, token);
        const updatedAppointments = [...appointments];
        updatedAppointments[editingIndex] = { ...formValues, id: appointmentId };
        setAppointments(updatedAppointments);
      } catch (error) {
        console.error('Failed to update appointment:', error);
      }
    } else {
      try {
        const newAppointment = await createAcademicAppointment(formValues, token);
        setAppointments((prevAppointments) => [...prevAppointments, newAppointment]);
      } catch (error) {
        console.error('Failed to create appointment:', error);
      }
    }
    setIsFormVisible(false);
    resetForm();
  };

  const handleEditAppointment = (index) => {
    if (index >= 0 && index < appointments.length) {
      setEditingIndex(index);
      const selectedAppointment = appointments[index];
      setInitialValues(selectedAppointment);
      setFormValues(selectedAppointment);
      setIsFormVisible(true);
    }
  };

  const handleCancelEdit = () => {
    setIsFormVisible(false);
    setEditingIndex(null);
    resetForm();
  };

  const handlePresentChange = () => {
    setIsPresent((prevIsPresent) => !prevIsPresent);
    setFormValues((prevValues) => ({
      ...prevValues,
      endDate: isPresent ? '' : null,
    }));
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "Present";
    }

    const date = new Date(dateString);
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };
  const options = [
    "Research Assistant",
    "Professor (Assistant)",
    "Professor (Associate)",
    "Professor (Full)",
    "Distinguished Professor",
    "Doctor",
  ];
  const sliceTre = (string) => {
    return string?.split("-")[1]?.toLowerCase() || "";
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 4 }}>
        <Button startIcon={<ArrowBack />} onClick={() => navigate(-1)} sx={{ mb: 4 }}>
          Back
        </Button>
        <Stack direction="row" spacing={0.5} color="primary.main" alignItems="center">
          <Work sx={{ color: "primary.main" }} />
          <Typography variant="h5" fontWeight="bold">
            Academic Appointments
          </Typography>
        </Stack>
        <Stack alignItems="start">
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.3,
              },
            }}
          >
            {appointments.map((appointment, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: index * 0.3 }} // Animated entrance
              >
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary" sx={{ typography: 'body2', lineHeight: 1.5 }}>
                    {`${appointment.startDate} - ${appointment.endDate == null ? "present" : appointment.endDate}`}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="primary" sx={{ transform: 'scale(1.2)' }} />
                    {index < appointments.length - 1 && <TimelineConnector sx={{ backgroundColor: '#1976d2' }} />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography variant='h5' component="span" sx={{ fontWeight: 600 }} color="primary">{appointment.academicAppointment} in {appointment.fieldOfStudy}</Typography>
                    <Typography variant="h6">
                      {appointment.institution}, {`Fac. of ${appointment.faculty}, Dept. of ${appointment.department}`}
                    </Typography>
                    <Stack direction="row" alignItems="center">
                      <img
                        style={{ marginRight: 6 }}
                        loading="lazy"
                        srcSet={`https://flagcdn.com/w40/${sliceTre(appointment.country)}.png 2x`}
                        src={`https://flagcdn.com/w20/${sliceTre(appointment.country)}.png`}
                        alt=""
                      />
                      <Typography variant="body2" color="text.secondary">
                        {appointment.country}
                      </Typography>
                    </Stack>
                    <div style={{ display: 'flex', marginTop: 8 }}>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => handleEditAppointment(index)}
                        sx={{ '&:hover': { backgroundColor: 'rgba(25, 118, 210, 0.1)' } }}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => handleDeleteAppointment(index)}
                        sx={{ '&:hover': { backgroundColor: 'rgba(255, 0, 0, 0.1)' }, ml: 1 }}
                      >
                        <Delete color='error' fontSize="small" />
                      </IconButton>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              </motion.div>
            ))}
          </Timeline>
        </Stack>

        {isFormVisible && (
          <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary" sx={{ mt: 4 }}>
                  {editingIndex !== null ? 'Edit Appointment' : 'Add New Appointment'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <UniversitySearch
                  onSelectUniversity={handleSelectUniversity}
                  selectedUniversity={formValues.institution}
                  label="Institution"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  name="faculty"
                  value={formValues.faculty}
                  onChange={handleInputChange}
                  fullWidth
                  label="Faculty"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DepartmentSearch
                  onSelectDepartment={(newValue) =>
                    handleInputChange({
                      target: {
                        name: 'department',
                        value: newValue,
                      },
                    })
                  }
                  selectedDepartment={formValues.department}
                  label="Department"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FieldOfStudySearch
                  onSelectFieldOfStudy={(newValue) =>
                    handleInputChange({
                      target: {
                        name: 'fieldOfStudy',
                        value: newValue,
                      },
                    })
                  }
                  selectedFieldOfStudy={formValues.fieldOfStudy}
                  label="Field of Study"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  type="number"
                  name="startDate"
                  value={formValues.startDate}
                  onChange={handleInputChange}
                  fullWidth
                  label="Start Year"
                  variant="outlined"
                  inputProps={{ min: 1900, max: new Date().getFullYear() }} // Set min and max year range
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  type="number"
                  name="endDate"
                  value={isPresent ? '' : formValues.endDate}
                  onChange={handleInputChange}
                  disabled={isPresent}
                  placeholder="End Year"
                  fullWidth
                  variant="outlined"
                  label="End Year"
                  InputLabelProps={{ shrink: true }}
                />
                <FormControlLabel
                  control={<Checkbox checked={isPresent} onChange={handlePresentChange} />}
                  label="Present"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CountrySelect
                  select={formValues.country}
                  setSelect={(newValue) => handleInputChange({
                    target: {
                      name: 'country',
                      value: newValue,
                    }
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  size="small"
                  freeSolo  // Elle girişe izin verir
                  options={options}
                  value={formValues.academicAppointment}
                  onChange={(event, newValue) => {
                    // newValue ya seçilen seçenek ya da elle girilen değerdir
                    handleInputChange({
                      target: { name: 'academicAppointment', value: newValue },
                    });
                  }}
                  onInputChange={(event, inputValue) => {
                    // Elle girilen değer değiştiğinde de state'i güncelle
                    handleInputChange({
                      target: { name: 'academicAppointment', value: inputValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Academic Appointment"
                      variant="outlined"
                      name="academicAppointment"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={!valid}
                  >
                    {editingIndex !== null ? 'Save Changes' : 'Add Appointment'}
                  </Button>
                  <Button variant="outlined" color="secondary" onClick={handleCancelEdit}>
                    <Cancel />
                    Cancel
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        )}
        {!isFormVisible && <Button
          variant="contained"
          startIcon={<Add />}
          onClick={handleAddAppointment}
        >
          Add New Appointment
        </Button>}
      </Paper>
    </Container>
  );
};

export default AcademicAppointmentsPage;
