import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Ensure axios is installed
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Container, Grid, IconButton, Paper, Stack, TextField, Button, Typography, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import { TimelineOppositeContent, timelineOppositeContentClasses } from '@mui/lab';
import { Edit, Save, Cancel, ArrowBack, Delete, Add, Work } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getAcademicExperienceForCurrentUser, updateAcademicDuties, deleteAcademicExperience, createAcademicAppointment, createAcademicDuties } from '../services/academicExperienceServices'; // Update this line
import { useUserContext } from '../auth/AuthProvider';
import CountrySelect from '../components/CountrySelect';
import { motion } from "framer-motion";
import UniversitySearch from '../components/profile/UniversitySearch';
import DepartmentSearch from '../components/profile/DepartmentSearch';
import FieldOfStudySearch from '../components/profile/FieldOfStudySearch';

const AcademicDutiesPage = () => {
    const navigate = useNavigate();
    const { token } = useUserContext();
    const [duties, setDuties] = useState([]);
    const [formValues, setFormValues] = useState({
        startDate: '',
        endDate: '',
        institution: '',
        department: '',
        country: '',
        duty: '',
    });
    const [isPresent, setIsPresent] = useState(false); // New state for checkbox
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [initialValues, setInitialValues] = useState({});
    const [valid, setValid] = useState(false);
    const handleSelectUniversity = (newValue) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            institution: newValue,
        }));
    };
    useEffect(() => {
        const { startDate, institution, country, duty } = formValues;
        setValid(startDate && institution && country && duty); // Updated validation
    }, [formValues]);

    useEffect(() => {
        const fetchDutys = async () => {
            try {
                const data = await getAcademicExperienceForCurrentUser("Academic Duties", token);
                console.log("data", data)
                setDuties(data);
            } catch (error) {
                console.error('Failed to fetch duties:', error);
            }
        };

        fetchDutys();
    }, [token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleAddduty = () => {
        setIsFormVisible(true);
        setEditingIndex(null);
        resetForm();
    };

    const resetForm = () => {
        setFormValues({
            startDate: '',
            endDate: '',
            institution: '',
            department: '',
            country: '',
            duty: '',
        });
        setIsPresent(false);
    };

    const handleDeleteduty = async (index) => {
        const dutyId = duties[index].id;
        try {
            await deleteAcademicExperience(dutyId, token);
            setDuties((prevdutys) => prevdutys.filter((_, i) => i !== index));
        } catch (error) {
            console.error("Failed to delete duty:", error);
        }
    };

    const handleSave = async () => {
        if (editingIndex !== null) {
            try {
                const dutyId = duties[editingIndex].id;
                await updateAcademicDuties(dutyId, formValues, token);
                const updateddutys = [...duties];
                updateddutys[editingIndex] = { ...formValues, id: dutyId };
                setDuties(updateddutys);
            } catch (error) {
                console.error('Failed to update duty:', error);
            }
        } else {
            try {
                const newduty = await createAcademicDuties(formValues, token);
                setDuties((prevdutys) => [...prevdutys, newduty]);
            } catch (error) {
                console.error('Failed to create duty:', error);
            }
        }
        setIsFormVisible(false);
        resetForm();
    };

    const handleEditduty = (index) => {
        if (index >= 0 && index < duties.length) {
            setEditingIndex(index);
            const selectedduty = duties[index];
            setInitialValues(selectedduty);
            setFormValues(selectedduty);
            setIsFormVisible(true);
        }
    };

    const handleCancelEdit = () => {
        setIsFormVisible(false);
        setEditingIndex(null);
        resetForm();
    };

    const handlePresentChange = () => {
        setIsPresent((prevIsPresent) => !prevIsPresent);
        setFormValues((prevValues) => ({
            ...prevValues,
            endDate: isPresent ? '' : null,
        }));
    };

    const formatDate = (dateString) => {
        if (!dateString) {
            return "Present";
        }

        const date = new Date(dateString);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    };
    const options = [
        "Rector",
        "Vice rector",
        "Chair",
        "Vice chair",
        "Dean",
        "Vice dean",
        "Summer training commission chairman",
        "Faculty board member",
        "University board member",
        "Accreditation board chairman",
        "Accreditation board member",
        "Student exchange program coordinator",
        "Chairman of the disciplinary committee",
        "Member of the disciplinary committee",
        "Department program coordinator",
        "Head of research assistants",
    ];
    const sliceTre = (string) => {
        return string?.split("-")[1]?.toLowerCase() || "";
    };
    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Paper elevation={3} sx={{ p: 4, borderRadius: 4 }}>
                <Button startIcon={<ArrowBack />} onClick={() => navigate(-1)} sx={{ mb: 4 }}>
                    Back
                </Button>
                <Stack direction="row" spacing={0.5} color="primary.main" alignItems="center">
                    <Work sx={{ color: "primary.main" }} />
                    <Typography variant="h5" fontWeight="bold">
                        Academic Duties
                    </Typography>
                </Stack>
                <Stack alignItems="start">
                    <Timeline
                        sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                                flex: 0.3,
                            },
                        }}
                    >
                        {duties.map((duty, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 1, delay: index * 0.3 }}
                            >
                                <TimelineItem>
                                    <TimelineOppositeContent color="text.secondary" sx={{ typography: 'body2', lineHeight: 1.5 }}>
                                        {`${duty.startDate} - ${duty.endDate == null ? "present" : duty.endDate}`}
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary" sx={{ transform: 'scale(1.2)' }} />
                                        {index < duties.length - 1 && <TimelineConnector sx={{ backgroundColor: '#1976d2' }} />}
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Typography variant="h5" component="span" sx={{ fontWeight: 600 }} color="primary">
                                            {duty.duty}
                                        </Typography>
                                        <Typography variant="h6">
                                            {duty.institution}{duty.department && ", Department of " + duty.department}
                                        </Typography>
                                        <Stack direction="row" alignItems="center">
                                            <img
                                                style={{ marginRight: 6 }}
                                                loading="lazy"
                                                srcSet={`https://flagcdn.com/w40/${sliceTre(duty.country)}.png 2x`}
                                                src={`https://flagcdn.com/w20/${sliceTre(duty.country)}.png`}
                                                alt=""
                                            />
                                            <Typography variant="body2" color="text.secondary">
                                                {duty.country}
                                            </Typography>
                                        </Stack>
                                        <div style={{ display: 'flex', marginTop: 8 }}>
                                            <IconButton size="small" color="primary" onClick={() => handleEditduty(index)}>
                                                <Edit fontSize="small" />
                                            </IconButton>
                                            <IconButton size="small" color="secondary" onClick={() => handleDeleteduty(index)}>
                                                <Delete color='error' fontSize="small" />
                                            </IconButton>
                                        </div>
                                        {isFormVisible && editingIndex !== null && editingIndex === index && (
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" color="primary" sx={{ mt: 4 }}>
                                                            {editingIndex !== null ? 'Edit duty' : 'Add New duty'}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <UniversitySearch
                                                            onSelectUniversity={handleSelectUniversity}
                                                            selectedUniversity={formValues.institution}
                                                            label="Institution"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <DepartmentSearch
                                                            onSelectDepartment={(newValue) =>
                                                                handleInputChange({
                                                                    target: {
                                                                        name: 'department',
                                                                        value: newValue,
                                                                    },
                                                                })
                                                            }
                                                            selectedDepartment={formValues.department}
                                                            label="Department (Optional)"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            size="small"
                                                            type="number"
                                                            name="startDate"
                                                            value={formValues.startDate}
                                                            onChange={handleInputChange}
                                                            fullWidth
                                                            label="Start Year"
                                                            variant="outlined"
                                                            inputProps={{ min: 1900, max: new Date().getFullYear() }} // Set min and max year range
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            size="small"
                                                            type="number"
                                                            name="endDate"
                                                            value={isPresent ? '' : formValues.endDate}
                                                            onChange={handleInputChange}
                                                            disabled={isPresent}
                                                            placeholder="End Year"
                                                            fullWidth
                                                            variant="outlined"
                                                            label="End Year"
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={isPresent} onChange={handlePresentChange} />}
                                                            label="Present"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <CountrySelect
                                                            select={formValues.country}
                                                            setSelect={(newValue) => handleInputChange({
                                                                target: {
                                                                    name: 'country',
                                                                    value: newValue,
                                                                }
                                                            })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Autocomplete
                                                            size="small"
                                                            freeSolo  // Elle girişe izin verir
                                                            options={options}
                                                            value={formValues.duty}
                                                            onChange={(event, newValue) => {
                                                                // newValue ya seçilen seçenek ya da elle girilen değerdir
                                                                handleInputChange({
                                                                    target: { name: 'duty', value: newValue },
                                                                });
                                                            }}
                                                            onInputChange={(event, inputValue) => {
                                                                // Elle girilen değer değiştiğinde de state'i güncelle
                                                                handleInputChange({
                                                                    target: { name: 'duty', value: inputValue },
                                                                });
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    fullWidth
                                                                    label="Academic Duty"
                                                                    variant="outlined"
                                                                    name="duty"
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={handleSave}
                                                                disabled={!valid}
                                                            >
                                                                {editingIndex !== null ? 'Save Changes' : 'Add duty'}
                                                            </Button>
                                                            <Button variant="outlined" color="secondary" onClick={handleCancelEdit}>
                                                                <Cancel />
                                                                Cancel
                                                            </Button>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        )}
                                    </TimelineContent>
                                </TimelineItem>
                            </motion.div>
                        ))}
                    </Timeline>
                </Stack>

                {isFormVisible && editingIndex == null && (
                    <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6" color="primary" sx={{ mt: 4 }}>
                                    {editingIndex !== null ? 'Edit duty' : 'Add New duty'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <UniversitySearch
                                    onSelectUniversity={handleSelectUniversity}
                                    selectedUniversity={formValues.institution}
                                    label="Institution"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <DepartmentSearch
                                    onSelectDepartment={(newValue) =>
                                        handleInputChange({
                                            target: {
                                                name: 'department',
                                                value: newValue,
                                            },
                                        })
                                    }
                                    selectedDepartment={formValues.department}
                                    label="Department (Optional)"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    type="number"
                                    name="startDate"
                                    value={formValues.startDate}
                                    onChange={handleInputChange}
                                    fullWidth
                                    label="Start Year"
                                    variant="outlined"
                                    inputProps={{ min: 1900, max: new Date().getFullYear() }} // Set min and max year range
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    type="number"
                                    name="endDate"
                                    value={isPresent ? '' : formValues.endDate}
                                    onChange={handleInputChange}
                                    disabled={isPresent}
                                    placeholder="End Year"
                                    fullWidth
                                    variant="outlined"
                                    label="End Year"
                                    InputLabelProps={{ shrink: true }}
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={isPresent} onChange={handlePresentChange} />}
                                    label="Present"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CountrySelect
                                    select={formValues.country}
                                    setSelect={(newValue) => handleInputChange({
                                        target: {
                                            name: 'country',
                                            value: newValue,
                                        }
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    size="small"
                                    freeSolo  // Elle girişe izin verir
                                    options={options}
                                    value={formValues.duty}
                                    onChange={(event, newValue) => {
                                        // newValue ya seçilen seçenek ya da elle girilen değerdir
                                        handleInputChange({
                                            target: { name: 'duty', value: newValue },
                                        });
                                    }}
                                    onInputChange={(event, inputValue) => {
                                        // Elle girilen değer değiştiğinde de state'i güncelle
                                        handleInputChange({
                                            target: { name: 'duty', value: inputValue },
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label="Academic Duty"
                                            variant="outlined"
                                            name="duty"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSave}
                                        disabled={!valid}
                                    >
                                        {editingIndex !== null ? 'Save Changes' : 'Add duty'}
                                    </Button>
                                    <Button variant="outlined" color="secondary" onClick={handleCancelEdit}>
                                        <Cancel />
                                        Cancel
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Paper>
                )}
                {!isFormVisible && <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={handleAddduty}
                >
                    Add New duty
                </Button>}
            </Paper>
        </Container>
    );
};

export default AcademicDutiesPage;
