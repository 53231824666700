const categories = [
    { "id": 1, "label": "Acoustics" },
    { "id": 2, "label": "Agriculture Dairy Animal Science" },
    { "id": 3, "label": "Agriculture Multidisciplinary" },
    { "id": 4, "label": "Agronomy" },
    { "id": 5, "label": "Allergy" },
    { "id": 6, "label": "Anesthesiology" },
    { "id": 7, "label": "Anthropology" },
    { "id": 8, "label": "Archaeology" },
    { "id": 9, "label": "Architecture" },
    { "id": 10, "label": "Area Studies" },
    { "id": 11, "label": "Art" },
    { "id": 12, "label": "Artificial Intelligence" },
    { "id": 13, "label": "Asian Studies" },
    { "id": 14, "label": "Astronomy Astrophysics" },
    { "id": 15, "label": "Automation Control Systems" },
    { "id": 16, "label": "Behavioral Sciences" },
    { "id": 17, "label": "Biochemical Research Methods" },
    { "id": 18, "label": "Biochemistry Molecular Biology" },
    { "id": 19, "label": "Biodiversity Conservation" },
    { "id": 20, "label": "Biology" },
    { "id": 21, "label": "Biophysics" },
    { "id": 22, "label": "Biotechnology Applied Microbiology" },
    { "id": 23, "label": "Blockchain Technology" },
    { "id": 24, "label": "Business" },
    { "id": 25, "label": "Business Finance" },
    { "id": 26, "label": "Cardiac Cardiovascular Systems" },
    { "id": 27, "label": "Cell Biology" },
    { "id": 28, "label": "Chemistry Analytical" },
    { "id": 29, "label": "Chemistry Applied" },
    { "id": 30, "label": "Chemistry Inorganic Nuclear" },
    { "id": 31, "label": "Chemistry Medicinal" },
    { "id": 32, "label": "Chemistry Multidisciplinary" },
    { "id": 33, "label": "Chemistry Organic" },
    { "id": 34, "label": "Chemistry Physical" },
    { "id": 35, "label": "Clinical Neurology" },
    { "id": 36, "label": "Communication" },
    { "id": 37, "label": "Computer Science Artificial Intelligence" },
    { "id": 38, "label": "Computer Science Cybernetics" },
    { "id": 39, "label": "Computer Science Hardware Architecture" },
    { "id": 40, "label": "Computer Science Information Systems" },
    { "id": 41, "label": "Computer Science Interdisciplinary Applications" },
    { "id": 42, "label": "Computer Science Software Engineering" },
    { "id": 43, "label": "Computer Science Theory Methods" },
    { "id": 44, "label": "Construction Building Technology" },
    { "id": 45, "label": "Critical Care Medicine" },
    { "id": 46, "label": "Crystallography" },
    { "id": 47, "label": "Cybersecurity" },
    { "id": 48, "label": "Data Science" },
    { "id": 49, "label": "Dentistry Oral Surgery Medicine" },
    { "id": 50, "label": "Dermatology" },
    { "id": 51, "label": "Developmental Biology" },
    { "id": 52, "label": "Ecology" },
    { "id": 53, "label": "Economics" },
    { "id": 54, "label": "Education Educational Research" },
    { "id": 55, "label": "Education Scientific Disciplines" },
    { "id": 56, "label": "Electrochemistry" },
    { "id": 57, "label": "Emergency Medicine" },
    { "id": 58, "label": "Endocrinology Metabolism" },
    { "id": 59, "label": "Energy Fuels" },
    { "id": 60, "label": "Engineering Aerospace" },
    { "id": 61, "label": "Engineering Biomedical" },
    { "id": 62, "label": "Engineering Chemical" },
    { "id": 63, "label": "Engineering Civil" },
    { "id": 64, "label": "Engineering Electrical Electronic" },
    { "id": 65, "label": "Engineering Environmental" },
    { "id": 66, "label": "Engineering Geological" },
    { "id": 67, "label": "Engineering Industrial" },
    { "id": 68, "label": "Engineering Manufacturing" },
    { "id": 69, "label": "Engineering Mechanical" },
    { "id": 70, "label": "Engineering Multidisciplinary" },
    { "id": 71, "label": "Entomology" },
    { "id": 72, "label": "Environmental Sciences" },
    { "id": 73, "label": "Environmental Studies" },
    { "id": 74, "label": "Evolutionary Biology" },
    { "id": 75, "label": "Film Radio Television" },
    { "id": 76, "label": "Fisheries" },
    { "id": 77, "label": "Food Science Technology" },
    { "id": 78, "label": "Forestry" },
    { "id": 79, "label": "Gastroenterology Hepatology" },
    { "id": 80, "label": "Genetics Heredity" },
    { "id": 81, "label": "Geochemistry Geophysics" },
    { "id": 82, "label": "Geography" },
    { "id": 83, "label": "Geography Physical" },
    { "id": 84, "label": "Geosciences Multidisciplinary" },
    { "id": 85, "label": "Geriatrics Gerontology" },
    { "id": 86, "label": "Gerontology" },
    { "id": 87, "label": "Green Sustainable Science Technology" },
    { "id": 88, "label": "Health Care Sciences Services" },
    { "id": 89, "label": "Health Policy Services" },
    { "id": 90, "label": "Hematology" },
    { "id": 91, "label": "History" },
    { "id": 92, "label": "History Philosophy Of Science" },
    { "id": 93, "label": "Horticulture" },
    { "id": 94, "label": "Hospitality Leisure Sport Tourism" },
    { "id": 95, "label": "Humanities Multidisciplinary" },
    { "id": 96, "label": "Imaging Science Photographic Technology" },
    { "id": 97, "label": "Immunology" },
    { "id": 98, "label": "Infectious Diseases" },
    { "id": 99, "label": "Information Science Library Science" },
    { "id": 100, "label": "Instruments Instrumentation" },
    { "id": 101, "label": "International Relations" },
    { "id": 102, "label": "Language Linguistics" },
    { "id": 103, "label": "Law" },
    { "id": 104, "label": "Linguistics" },
    { "id": 105, "label": "Literary Reviews" },
    { "id": 106, "label": "Literary Theory Criticism" },
    { "id": 107, "label": "Literature" },
    { "id": 108, "label": "Literature Romance" },
    { "id": 109, "label": "Management" },
    { "id": 110, "label": "Marine Freshwater Biology" },
    { "id": 111, "label": "Materials Science Biomaterials" },
    { "id": 112, "label": "Materials Science Ceramics" },
    { "id": 113, "label": "Materials Science Characterization Testing" },
    { "id": 114, "label": "Materials Science Coatings Films" },
    { "id": 115, "label": "Materials Science Composites" },
    { "id": 116, "label": "Materials Science Multidisciplinary" },
    { "id": 117, "label": "Mathematical Computational Biology" },
    { "id": 118, "label": "Mathematics" },
    { "id": 119, "label": "Mathematics Applied" },
    { "id": 120, "label": "Mathematics Interdisciplinary Applications" },
    { "id": 121, "label": "Mechanics" },
    { "id": 122, "label": "Medical Informatics" },
    { "id": 123, "label": "Medical Laboratory Technology" },
    { "id": 124, "label": "Medicine General Internal" },
    { "id": 125, "label": "Medicine Research Experimental" },
    { "id": 126, "label": "Medieval Renaissance Studies" },
    { "id": 127, "label": "Metallurgy Metallurgical Engineering" },
    { "id": 128, "label": "Meteorology Atmospheric Sciences" },
    { "id": 129, "label": "Microbiology" },
    { "id": 130, "label": "Multidisciplinary Sciences" },
    { "id": 131, "label": "Music" },
    { "id": 132, "label": "Nanoscience Nanotechnology" },
    { "id": 133, "label": "Neurosciences" },
    { "id": 134, "label": "Nuclear Science Technology" },
    { "id": 135, "label": "Nursing" },
    { "id": 136, "label": "Nutrition Dietetics" },
    { "id": 137, "label": "Obstetrics Gynecology" },
    { "id": 138, "label": "Oceanography" },
    { "id": 139, "label": "Oncology" },
    { "id": 140, "label": "Ophthalmology" },
    { "id": 141, "label": "Optics" },
    { "id": 142, "label": "Orthopedics" },
    { "id": 143, "label": "Otorhinolaryngology" },
    { "id": 144, "label": "Parasitology" },
    { "id": 145, "label": "Pathology" },
    { "id": 146, "label": "Pediatrics" },
    { "id": 147, "label": "Pharmacology Pharmacy" },
    { "id": 148, "label": "Philosophy" },
    { "id": 149, "label": "Physics Applied" },
    { "id": 150, "label": "Physics Atomic Molecular Chemical" },
    { "id": 151, "label": "Physics Condensed Matter" },
    { "id": 152, "label": "Physics Fluids Plasmas" },
    { "id": 153, "label": "Physics Mathematical" },
    { "id": 154, "label": "Physics Multidisciplinary" },
    { "id": 155, "label": "Physics Nuclear" },
    { "id": 156, "label": "Physics Particles Fields" },
    { "id": 157, "label": "Planning Development" },
    { "id": 158, "label": "Plant Sciences" },
    { "id": 159, "label": "Political Science" },
    { "id": 160, "label": "Polymer Science" },
    { "id": 161, "label": "Primary Health Care" },
    { "id": 162, "label": "Psychiatry" },
    { "id": 163, "label": "Psychology" },
    { "id": 164, "label": "Psychology Applied" },
    { "id": 165, "label": "Psychology Clinical" },
    { "id": 166, "label": "Psychology Developmental" },
    { "id": 167, "label": "Psychology Educational" },
    { "id": 168, "label": "Psychology Experimental" },
    { "id": 169, "label": "Psychology Mathematical" },
    { "id": 170, "label": "Psychology Multidisciplinary" },
    { "id": 171, "label": "Public Administration" },
    { "id": 172, "label": "Public Environmental Occupational Health" },
    { "id": 173, "label": "Public Relations" },
    { "id": 174, "label": "Quantum Computing" },
    { "id": 175, "label": "Radiology Nuclear Medicine Medical Imaging" },
    { "id": 176, "label": "Rehabilitation" },
    { "id": 177, "label": "Religion" },
    { "id": 178, "label": "Remote Sensing" },
    { "id": 179, "label": "Reproductive Biology" },
    { "id": 180, "label": "Respiratory System" },
    { "id": 181, "label": "Rheumatology" },
    { "id": 182, "label": "Robotics" },
    { "id": 183, "label": "Social Sciences Biomedical" },
    { "id": 184, "label": "Social Work" },
    { "id": 185, "label": "Sociology" },
    { "id": 186, "label": "Soil Science" },
    { "id": 187, "label": "Spectroscopy" },
    { "id": 188, "label": "Statistics Probability" },
    { "id": 189, "label": "Surgery" },
    { "id": 190, "label": "Telecommunications" },
    { "id": 191, "label": "Thermodynamics" },
    { "id": 192, "label": "Toxicology" },
    { "id": 193, "label": "Transplantation" },
    { "id": 194, "label": "Transportation" },
    { "id": 195, "label": "Tropical Medicine" },
    { "id": 196, "label": "Urban Studies" },
    { "id": 197, "label": "Urology Nephrology" },
    { "id": 198, "label": "Veterinary Sciences" },
    { "id": 199, "label": "Virology" },
    { "id": 200, "label": "Zoology Ecology" }
]
export default categories;