import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  Stack,
} from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import GroupIcon from '@mui/icons-material/Group';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import axios from 'axios';
import AcademicAppointmentsPage from './AcademicAppointmentsPage';
import DirectedThesisPage from './DirectedThesisPage';
import { Add, SystemUpdateAltTwoTone } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import { useUserContext } from '../../auth/AuthProvider';
import ThesisJuryMemberPage from './ThesisJuryMemberPage';
import AcademicDutiesPage from './AcademicDutiesPage';
import StudentProjectPage from './StudentProjectPage';
import StudentCompetitionPage from './StudentCompetitionPage';
const baseUrl = process.env.REACT_APP_BASE_URL;



const publications = [
  { id: 0, key: "Academic Appointments", value: "Academic Appointments", icon: <WorkIcon sx={{ color: 'primary.main' }} />, endpoint: "/user-academic-appointments" },
  { id: 1, key: "Directed Thesis", value: "Directed Thesis", icon: <SchoolIcon sx={{ color: 'primary.main' }} />, endpoint: "/user-directed-thesis" },
  { id: 2, key: "Thesis Jury Members", value: "Thesis Jury Members", icon: <GroupIcon sx={{ color: 'primary.main' }} />, endpoint: "/thesis-jury-member" },
  { id: 3, key: "Academic Duties", value: "Academic Duties", icon: <AssignmentIcon sx={{ color: 'primary.main' }} />, endpoint: "/academic-duties" },
  { id: 4, key: "Student Projects", value: "Student Projects", icon: <SystemUpdateAltTwoTone sx={{ color: 'primary.main' }} />, endpoint: "/student-project" },
  { id: 5, key: "Student Competitions", value: "Student Competitions", icon: <EmojiEventsIcon sx={{ color: 'primary.main' }} />, endpoint: "/student-competition" }
];

const AcademicExperiencesPage = () => {
  const [selectedType, setSelectedType] = useState(publications[0].value);
  const [data, setData] = useState([]);
  const selectedPublication = publications.find(pub => pub.value === selectedType);
  const { userId } = useParams();
  const { user } = useUserContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}${selectedPublication.endpoint}`);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [selectedType]);

  const handlePublicationClick = (value) => {
    setSelectedType(value);
  };

  function formatString(input) {
    return input.toLowerCase().replace(/\s+/g, '-');
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Paper elevation={3} sx={{ padding: 2, borderRadius: '10px' }}>
          <Typography variant="h5" gutterBottom>
            Academic Experiences
          </Typography>
          {publications.map((pub) => (
            <Button
              key={pub.id}
              variant={selectedType === pub.value ? "contained" : "outlined"}
              onClick={() => handlePublicationClick(pub.value)}
              startIcon={pub.icon}
              fullWidth
              sx={{
                marginBottom: 1,
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: selectedType === pub.value ? '#0056b3' : '#e0e0e0',
                },
              }}
            >
              {pub.key}
            </Button>
          ))}
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        <Paper elevation={3} sx={{ padding: 2, borderRadius: '10px' }}>
          <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection="row">
            <Stack direction="row" spacing={0.5}>
              {selectedPublication.icon}
              <Typography variant="h6" color="primary.main" sx={{ ml: 1 }}>
                {selectedType}
              </Typography>
            </Stack>
            {user.id == userId && (
              <Link to={`/${formatString(selectedType)}-edit`}>
                <Button variant="contained" startIcon={<Add />}>
                  Add {selectedType}
                </Button>
              </Link>
            )}
          </Box>
          <Box sx={{ mt: 2 }}>
            {data.length > 0 ? (
              data.map((item, index) => (
                <Typography key={index}>{item.title}</Typography>
              ))
            ) : (
              selectedType === "Academic Appointments" ? (
                <AcademicAppointmentsPage />
              ) : selectedType === "Directed Thesis" ? (
                <DirectedThesisPage />
              ) : selectedType === "Thesis Jury Members" ? (
                <ThesisJuryMemberPage />
              ) : selectedType === 'Academic Duties' ? (
                <AcademicDutiesPage/>
              ) :  selectedType === 'Student Projects' ? (
                <StudentProjectPage/>
              ) : selectedType === "Student Competitions" ? (
                <StudentCompetitionPage/>
              )
              : null
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AcademicExperiencesPage;
