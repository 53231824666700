import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Container, Grid, IconButton, Paper, Stack, TextField, Button, Typography } from '@mui/material';
import { TimelineOppositeContent, timelineOppositeContentClasses } from '@mui/lab';
import { Edit, Delete, ArrowBack, Add, Clear } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { useUserContext } from '../auth/AuthProvider';
import CountrySelect from '../components/CountrySelect';
import { createStudentProject, deleteAcademicExperience, getAcademicExperienceForCurrentUser, updateStudentProject } from '../services/academicExperienceServices';
import UniversitySearch from '../components/profile/UniversitySearch';
import DepartmentSearch from '../components/profile/DepartmentSearch';

const StudentProjectPage = () => {
  const navigate = useNavigate();
  const { token } = useUserContext();
  const [projects, setProjects] = useState([]);
  const [formValues, setFormValues] = useState({
    advisors: [],         // Change to array
    projectName: '',
    university: '',
    department: '',
    year: '',
    country: ''
  });
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [valid, setValid] = useState(false);
  const [newAdvisor, setNewAdvisor] = useState('');
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const data = await getAcademicExperienceForCurrentUser("Student Project", token); // Fetch student projects
        console.log("Student Projects:", data);
        setProjects(data);
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      }
    };

    fetchProjects();
  }, [token]);

  useEffect(() => {
    const { projectName, university, year, country, advisors } = formValues;
    setValid(advisors.length > 0 && projectName && university && year && country);
    console.log(formValues)
  }, [formValues]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'advisors') {
      setNewAdvisor(value);
    } else {
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    }
  };

  const handleAddProject = () => {
    setIsFormVisible(true);
    setEditingIndex(null);
    resetForm();
  };

  const resetForm = () => {
    setFormValues({
      advisors: [],
      projectName: '',
      university: '',
      department: '',
      year: '',
      country: ''
    });
  };

  const handleDeleteProject = async (index) => {
    const projectId = projects[index].id;
    try {
      await deleteAcademicExperience(projectId, token);
      setProjects((prevProjects) => prevProjects.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Failed to delete project:", error);
    }
  };
  const handleAddAdvisor = () => {
    if (newAdvisor.trim() && !formValues.advisors.includes(newAdvisor.trim())) {
      setFormValues((prevValues) => ({
        ...prevValues,
        advisors: [...prevValues.advisors, newAdvisor.trim()],
      }));
      setNewAdvisor('');
    }
  };

  const handleRemoveAdvisor = (advisorToRemove) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      advisors: prevValues.advisors.filter(advisor => advisor !== advisorToRemove),
    }));
  };
  const handleSave = async () => {
    try {
      const formattedValues = {
        ...formValues,
        // advisors'ı olduğu gibi bırakıyoruz, birleştirmiyoruz
        advisors: formValues.advisors, // Do not join, keep as an array
      };

      if (editingIndex !== null) {
        const projectId = projects[editingIndex].id;
        await updateStudentProject(projectId, formattedValues, token);
        const updatedProjects = [...projects];
        updatedProjects[editingIndex] = { ...formattedValues, id: projectId };
        setProjects(updatedProjects);
      } else {
        const newProject = await createStudentProject(formattedValues, token);
        setProjects((prevProjects) => [...prevProjects, newProject]);
      }
      setIsFormVisible(false);
      resetForm();
    } catch (error) {
      console.error(editingIndex !== null ? 'Failed to update project:' : 'Failed to create project:', error);
    }
  };

  const handleEditProject = (index) => {
    if (index >= 0 && index < projects.length) {
      setEditingIndex(index);
      const selectedProject = projects[index];
      setFormValues({
        ...selectedProject,
        // advisors alanı bir dizi olduğuna göre doğrudan kullanın
        advisors: selectedProject.advisors || [], // Boş bir dizi olarak ayarla, eğer advisors null veya undefined ise
      });
      setIsFormVisible(true);
    }
  };

  const handleCancelEdit = () => {
    setIsFormVisible(false);
    setEditingIndex(null);
    resetForm();
  };
  const sliceTre = (string) => {
    return string?.split("-")[1]?.toLowerCase() || "";
  };

  const handleSelectUniversity = (newValue) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      university: newValue,
    }));
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 4 }}>
        <Button startIcon={<ArrowBack />} onClick={() => navigate(-1)} sx={{ mb: 4 }}>
          Back
        </Button>
        <Typography variant="h5" fontWeight="bold" color="primary" gutterBottom>
          Student Projects
        </Typography>
        <Timeline
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.3,
            },
          }}
        >
          {projects.map((project, index) => (
            <TimelineItem key={project.id}>
              <TimelineOppositeContent color="text.secondary" sx={{ typography: 'body2', lineHeight: 1.5 }}>
                {project.year}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" sx={{ transform: 'scale(1.2)' }} />
                {index < projects.length - 1 && <TimelineConnector sx={{ backgroundColor: '#1976d2' }} />}
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant='h5' component="span" sx={{ fontWeight: 600 }} color="primary">
                  {project.projectName}
                </Typography>
                <Typography variant="h6">
                  {project.advisors?.map((advisor, index) => (
                    `${advisor}${index < project.advisors.length - 1 ? ', ' : ''} `
                  ))}
                   ({project.year}). {project.university}{project.department && ", Department of " + project.department}
                </Typography>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Stack direction="row" alignItems="center">
                    <img
                      style={{ marginRight: 6 }}
                      loading="lazy"
                      srcSet={`https://flagcdn.com/w40/${sliceTre(project.country)}.png 2x`}
                      src={`https://flagcdn.com/w20/${sliceTre(project.country)}.png`}
                      alt=""
                    />
                    <Typography variant="body2" color="text.secondary">
                      {project.country}
                    </Typography>
                  </Stack>
                </Stack>
                <div style={{ display: 'flex', marginTop: 8 }}>
                  <IconButton size="small" color="primary" onClick={() => handleEditProject(index)}>
                    <Edit fontSize="small" />
                  </IconButton>
                  <IconButton size="small" color="secondary" onClick={() => handleDeleteProject(index)}>
                    <Delete color='error' fontSize="small" />
                  </IconButton>
                </div>
                {isFormVisible && editingIndex !== null && editingIndex == index && (
                  <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="primary" sx={{ mt: 4 }}>
                          {editingIndex !== null ? 'Edit Project' : 'Add New Project'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          size='small'
                          fullWidth
                          label="Project Name"
                          name="projectName"
                          value={formValues.projectName}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          size='small'
                          name='advisors'
                          fullWidth
                          label="Add Advisor"
                          value={newAdvisor}  // newAdvisor state'i buraya bağlı olmalı
                          onChange={handleInputChange}  // onChange ile yazı yazma olayını dinle
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleAddAdvisor();
                              e.preventDefault();  // Enter tuşuna basıldığında formun submit edilmesini engelle
                            }
                          }}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAddAdvisor}
                          sx={{ mt: 1 }}
                        >
                          Add
                        </Button>
                        {/* Display the advisors as a list */}
                        <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                          Current Advisors:
                        </Typography>
                        <Stack spacing={1}>
                          {formValues.advisors.length > 0 ? (
                            formValues.advisors.map((advisor, index) => (
                              <Stack direction="row" key={index} alignItems="center" spacing={1}>
                                <Typography variant="body2">{advisor}</Typography>
                                <IconButton size="small" onClick={() => handleRemoveAdvisor(advisor)}>
                                  <Clear fontSize="small" color="error" />
                                </IconButton>
                              </Stack>
                            ))
                          ) : (
                            <Typography variant="body2" color="text.secondary">No advisors added.</Typography>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <UniversitySearch
                          onSelectUniversity={handleSelectUniversity}
                          selectedUniversity={formValues.university}
                          label="Institution/ Organized"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <DepartmentSearch
                          onSelectDepartment={(newValue) =>
                            handleInputChange({
                              target: {
                                name: 'department',
                                value: newValue,
                              },
                            })
                          }
                          selectedDepartment={formValues.department}
                          label="Department (Optional)"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          size='small'
                          fullWidth
                          label="Year"
                          name="year"
                          type="number"
                          value={formValues.year}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CountrySelect
                          select={formValues.country}
                          setSelect={(newValue) => handleInputChange({
                            target: {
                              name: 'country',
                              value: newValue,
                            }
                          })}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSave}
                          disabled={!valid} // Disable button if the form is not valid
                        >
                          {editingIndex !== null ? 'Update Project' : 'Create Project'}
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={handleCancelEdit}
                          sx={{ ml: 2 }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                )}
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>

        {isFormVisible && editingIndex == null && (
          <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary" sx={{ mt: 4 }}>
                  {editingIndex !== null ? 'Edit Project' : 'Add New Project'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  size='small'
                  fullWidth
                  label="Project Name"
                  name="projectName"
                  value={formValues.projectName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size='small'
                  name='advisors'
                  fullWidth
                  label="Add Advisor"
                  value={newAdvisor}  // newAdvisor state'i buraya bağlı olmalı
                  onChange={handleInputChange}  // onChange ile yazı yazma olayını dinle
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleAddAdvisor();
                      e.preventDefault();  // Enter tuşuna basıldığında formun submit edilmesini engelle
                    }
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddAdvisor}
                  sx={{ mt: 1 }}
                >
                  Add
                </Button>
                {/* Display the advisors as a list */}
                <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                  Current Advisors:
                </Typography>
                <Stack spacing={1}>
                  {formValues.advisors.length > 0 ? (
                    formValues.advisors.map((advisor, index) => (
                      <Stack direction="row" key={index} alignItems="center" spacing={1}>
                        <Typography variant="body2">{advisor}</Typography>
                        <IconButton size="small" onClick={() => handleRemoveAdvisor(advisor)}>
                          <Clear fontSize="small" color="error" />
                        </IconButton>
                      </Stack>
                    ))
                  ) : (
                    <Typography variant="body2" color="text.secondary">No advisors added.</Typography>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <UniversitySearch
                  onSelectUniversity={handleSelectUniversity}
                  selectedUniversity={formValues.university}
                  label="Institution/ Organized"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DepartmentSearch
                  onSelectDepartment={(newValue) =>
                    handleInputChange({
                      target: {
                        name: 'department',
                        value: newValue,
                      },
                    })
                  }
                  selectedDepartment={formValues.department}
                  label="Department (Optional)"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size='small'
                  fullWidth
                  label="Year"
                  name="year"
                  type="number"
                  value={formValues.year}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CountrySelect
                  select={formValues.country}
                  setSelect={(newValue) => handleInputChange({
                    target: {
                      name: 'country',
                      value: newValue,
                    }
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={!valid} // Disable button if the form is not valid
                >
                  {editingIndex !== null ? 'Update Project' : 'Create Project'}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancelEdit}
                  sx={{ ml: 2 }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
        {!isFormVisible && <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleAddProject}
          sx={{ mt: 3 }}
        >
          Add New Project
        </Button>}
      </Paper>
    </Container>
  );
};

export default StudentProjectPage;
