import React, { useEffect, useState } from 'react';
import { Stack, Typography, IconButton, CircularProgress } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineOppositeContent, timelineOppositeContentClasses, TimelineContent } from '@mui/lab';
import { motion } from 'framer-motion';
import { Edit, Delete, Language } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../auth/AuthProvider';
import { getAcademicExperienceById } from '../../services/academicExperienceServices';

const DirectedThesisPage = () => {
  const [theses, setTheses] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userId } = useParams();
  const { token } = useUserContext();

  useEffect(() => {
    const fetchTheses = async () => {
      setLoading(true);
      try {
        const response = await getAcademicExperienceById(userId, "Directed Thesis", token); // Fetch directed theses
        setTheses(response); // Assume response is the theses array
      } catch (error) {
        console.error("Failed to fetch theses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTheses();
  }, [userId, token]);

  if (loading) {
    return (
      <Stack alignItems="center" justifyContent="center" >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack alignItems="start" sx={{ width: '100%', p: 2 }}>
      {theses.length === 0 ? (
        <Typography variant="h6" color="text.secondary" align="center">
          No directed theses have been added yet.
        </Typography>
      ) : (
        <Timeline sx={{ [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.3 } }}>
          {theses.map((thesis, index) => (
            <motion.div
              key={thesis.id}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: index * 0.3 }}
            >
              <TimelineItem>
                <TimelineOppositeContent color="text.secondary" sx={{ typography: 'body2', lineHeight: 1.5 }}>
                  {thesis.year}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary" sx={{ transform: 'scale(1.2)' }} />
                  {index < theses.length - 1 && <TimelineConnector sx={{ backgroundColor: '#1976d2' }} />}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant='h5' component="span" sx={{ fontWeight: 600 }} color="primary">
                    {thesis.thesisDegree} {thesis.position} ({thesis.year})
                  </Typography>
                  <Typography variant="h6">
                    {thesis.title}. <b>{thesis.thesisDegree} by {thesis.name}</b>.
                  </Typography>
                  <Typography variant="h6">
                    {thesis.university}, {`Department of ${thesis.department}`}
                  </Typography>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Language color='primary.main'/>
                    <Typography variant="body1" color="gray">
                      {thesis.language}
                    </Typography>
                  </Stack>

                </TimelineContent>
              </TimelineItem>
            </motion.div>
          ))}
        </Timeline>
      )}
    </Stack>
  );
};

export default DirectedThesisPage;
