import { BarChart } from '@mui/x-charts';
import { Paper, Stack, Typography, useTheme, useMediaQuery, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getAllPublicationsByYear } from '../../services/publicationService';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../auth/AuthProvider';

const BarChartBox = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [publicationsByYear, setPublicationsByYear] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { userId } = useParams();
    const { token } = useUserContext();

    useEffect(() => {
        const fetchPublications = async () => {
            try {
                const data = await getAllPublicationsByYear(token, userId);
                setPublicationsByYear(data || {});
                console.log(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPublications();
    }, [userId, token]);

    // Loading spinner
    if (loading) {
        return (
            <Paper sx={{ borderRadius: 3, p: 2, overflowX: 'auto' }}>
                <Stack alignItems="center" justifyContent="center" height={isMobile ? 200 : 300}>
                    <CircularProgress size={60} thickness={4} />
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        Loading Publications Data...
                    </Typography>
                </Stack>
            </Paper>
        );
    }

    if (error) return <div>Error: {error}</div>;

    // Convert object to array
    const publicationsArray = Object.values(publicationsByYear).flat();

    // Group data by years and types
    const years = [...new Set(publicationsArray.map(pub => parseInt(pub.year, 10)))].sort();

    // Format years for display (e.g., "2.020" instead of "2020")
    const formattedYears = years.map(year => {
        return year.toString().replace(/^(\d)(\d{3})$/, '$1$2'); // Convert to "2.020" format
    });

    const publicationTypes = [...new Set(publicationsArray.map(pub => pub.type))];

    // Create series data for the chart
    const seriesData = publicationTypes.map(type => ({
        data: years.map(year => {
            // Count publications for each year and type
            return publicationsArray.filter(pub => pub.year === year && pub.type === type).length;
        }),
        title: type.replace('_', ' '), // Convert type to a readable format
    }));

    function convertEnumToTitle(enumString) {
        // Convert enum string to title case with specific rules
        const words = enumString.toLowerCase().split('_'); // Convert to lowercase and split by underscores
        return words.map((word, index) => {
            // Capitalize only the first word; others remain lowercase
            return index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word; // Capitalize first letter
        }).join(' '); // Join the words back into a single string
    }

    return (
        <Paper sx={{ borderRadius: 3, p: 2, overflowX: 'auto' }}>
            <Stack spacing={2} sx={{ overflowX: "scroll" }}>
                <Typography variant="h6">Publications by Year</Typography>
                <BarChart
                    xAxis={[{
                        scaleType: 'band',
                        data: formattedYears, // Use formatted years directly
                        dataKey:'year'
                    }]}
                    series={seriesData.map(series => ({
                        data: series.data,
                        label: convertEnumToTitle(series.title),
                    }))}
                    width={isMobile ? 300 : 900}
                    height={isMobile ? 200 : 300}
                    tooltip={{
                        labelFormatter: (value) => `Publications: ${value}`, // Customize tooltip label
                    }}
                />
            </Stack>
        </Paper>
    );
};

export default BarChartBox;
