import { Add, Cancel, Folder, Save } from '@mui/icons-material';
import { Button, CircularProgress, Paper, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { createFolder } from '../../../services/editRawDataService'; // Import your service function
import { useUserContext } from '../../../auth/AuthProvider';
import { useParams } from 'react-router-dom';

const CreateFolder = ({ onCancel, onAddFolder }) => {
    const [folderName, setFolderName] = useState('');
    const { token } = useUserContext();
    const { publicationId } = useParams();
    const [loading, setLoading] = useState(false); // State for loading status

    const fetchCreateFolder = async () => {
        setLoading(true); // Set loading to true when starting the request
        try {
            // Call the API to create a new folder
            const newFolder = await createFolder(publicationId, token, folderName); // Pass the folder name to your API function
            setFolderName(''); // Clear the input field after successful creation
            onAddFolder(newFolder); // Refresh the folder list
            onCancel(); // Close the create folder form
        } catch (error) {
            console.error('Error creating folder:', error);
            // Optionally, you can display an error message to the user here
        } finally {
            setLoading(false); // Reset loading status
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission
        if (folderName) {
            fetchCreateFolder(); // Call the function to create a folder
        }
    };

    return (
        <Paper elevation={1}>
            <Stack>
                <Stack p={1}>
                    <Stack p={1} alignItems="center" direction="row" spacing={0.5}>
                        <Folder color='primary' />
                        <form onSubmit={handleSubmit}>
                            <TextField 
                                size="small" 
                                value={folderName}
                                onChange={(e) => setFolderName(e.target.value)} // Update state on input change
                                placeholder="Enter folder name" 
                                required // Ensure the input is required
                            />
                            <Button 
                                type="submit" // Set button type to submit
                                startIcon={loading ? <CircularProgress size={20} /> : <Add />} // Show loading spinner if loading
                                disabled={loading} // Disable the button while loading
                            >
                                {loading ? 'Creating...' : 'Add'} {/* Change button text based on loading state */}
                            </Button>
                        </form>
                        <Button 
                            color='error' 
                            onClick={onCancel} 
                            startIcon={<Cancel />}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    );
}

export default CreateFolder;
