import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

// Helper function to create headers with the token
const getAuthHeaders = (token) => {
    return token ? { Authorization: `Bearer ${token}` } : {};
};

// Get folders by publication ID
export const getFolderAll = async (publicationId, token) => {
    try {
        const response = await axios.get(`${baseUrl}/api/v1/folder-list/publication/${publicationId}`, {
            headers: getAuthHeaders(token)
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching folders:', error);
        throw error;
    }
};

// Create a new folder
export const createFolder = async (publicationId, token, name) => {
    try {
        const response = await axios.post(
            `${baseUrl}/api/v1/folder-list/${publicationId}`, // Use POST method
            { name }, // Send the folder name as the request body
            { headers: getAuthHeaders(token) } // Include the authorization headers
        );
        return response.data; // Return the response data
    } catch (error) {
        console.error('Error creating folder:', error);
        throw error; // Rethrow the error to handle it where this function is called
    }
};

// Move a folder up
export const moveFolderUp = async (folderId, token) => {
    try {
        const response = await axios.put(
            `${baseUrl}/api/v1/folder-list/move-up/${folderId}`, // Endpoint for moving the folder up
            {}, // You may send any required body data here if necessary
            { headers: getAuthHeaders(token) } // Include the authorization headers
        );
        return response.data; // Return the response data
    } catch (error) {
        console.error('Error moving folder up:', error);
        throw error; // Rethrow the error to handle it where this function is called
    }
};

// Move a folder down
export const moveFolderDown = async (folderId, token) => {
    try {
        const response = await axios.put(
            `${baseUrl}/api/v1/folder-list/move-down/${folderId}`, // Endpoint for moving the folder down
            {}, // You may send any required body data here if necessary
            { headers: getAuthHeaders(token) } // Include the authorization headers
        );
        return response.data; // Return the response data
    } catch (error) {
        console.error('Error moving folder down:', error);
        throw error; // Rethrow the error to handle it where this function is called
    }
};
export const deleteFolder = async (folderId, token) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/v1/folder-list/delete/${folderId}`, {
            headers: getAuthHeaders(token), // Include the authorization headers
        });

        return response.data; // Return the response data
    } catch (error) {
        console.error('Error deleting folder:', error);
        throw error; // Rethrow the error to handle it where this function is called
    }
};
export const updateFolderName = async (folderId, token,name) => {
    try {
        const response = await axios.put(`${baseUrl}/api/v1/folder-list/update-name/${folderId}`,{name}, {
            headers: getAuthHeaders(token), // Include the authorization headers
        });

        return response.data; // Return the response data
    } catch (error) {
        console.error('Error deleting folder:', error);
        throw error; // Rethrow the error to handle it where this function is called
    }
};
export const createRawData = async (folderId, data, token) => {
    try {
        const formData = new FormData();
        
        console.log(folderId)
        // Add folderId and rawDataRequest properties to formData
        
        formData.append('name', data.name);
        formData.append('comment', data.comment);
        formData.append('priceSuggestion', data.priceSuggestion);
        
        // Append any files in data (if applicable)
        if (data.previewImage) {
            formData.append('previewImage', data.previewImage);
        }
        if (data.rawData) {
            formData.append('rawData', data.rawData);
        }

        // Make Axios request
        const response = await axios.post(`${baseUrl}/api/v1/raw-data-list/create/${folderId}`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 201) {
            console.log("Data created successfully.");
        }
        return response.data
    } catch (error) {
        console.error("Error creating raw data:", error);
    }
};
export const updateRawData = async (rawDataId, data, token) => {
    try {
        const formData = new FormData();
        
        formData.append('name', data.name);
        formData.append('comment', data.comment);
        formData.append('priceSuggestion', data.priceSuggestion);
        
        // Append file data if valid
        if (data.previewImage !== "null")  {
            console.log("previewImage",data.previewImage)
            formData.append('previewImage', data.previewImage);
        }
        if (data.rawData !== "null") {
            console.log("rawdata",data.rawData)
            formData.append('rawData', data.rawData);
        }

        const response = await axios.put(`${baseUrl}/api/v1/raw-data-list/${rawDataId}`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        });

        if (response.status === 200 || response.status === 201) {
            console.log("Data updated successfully.");
        }

        return response.data;
    } catch (error) {
        console.error("Error updating raw data:", error);
        throw error;
    }
};
export const moveRawDataUp = async (rawDataId, token) => {
    try {
        const response = await axios.put(`${baseUrl}/api/v1/raw-data-list/${rawDataId}/moveUp`, {}, {
            headers: getAuthHeaders(token), // Include the authorization headers
        });

        return response.data; // Return the response data
    } catch (error) {
        console.error('Error moving raw data up:', error);
        throw error; // Rethrow the error to handle it where this function is called
    }
};
export const moveRawDataDown = async (rawDataId, token) => {
    try {
        const response = await axios.put(`${baseUrl}/api/v1/raw-data-list/${rawDataId}/moveDown`, {}, {
            headers: getAuthHeaders(token), // Include the authorization headers
        });

        return response.data; // Return the response data
    } catch (error) {
        console.error('Error moving raw data down:', error);
        throw error; // Rethrow the error to handle it where this function is called
    }
};
export const deleteRawData = async (rawDataId, token) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/v1/raw-data-list/${rawDataId}`, {
            headers: getAuthHeaders(token),
        });
        return response.data; // Return the success message from the backend
    } catch (error) {
        if (error.response) {
            // The request was made, but the server responded with an error
            console.error("Error deleting raw data:", error.response.data);
            return error.response.data;
        } else if (error.request) {
            // The request was made but no response was received
            console.error("No response received:", error.request);
            return "No response from server.";
        } else {
            // Something else happened
            console.error("Error:", error.message);
            return "An error occurred.";
        }
    }
};