import React, { useEffect, useState } from 'react';
import { Stack, Typography, CircularProgress } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineOppositeContent, timelineOppositeContentClasses, TimelineContent } from '@mui/lab';
import { motion } from 'framer-motion';
import { getAcademicExperienceById } from '../../services/academicExperienceServices';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../auth/AuthProvider';

const AcademicDutiesPage = () => {
  const [duties, setDuties] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userId } = useParams();
  const { token } = useUserContext();

  useEffect(() => {
    const fetchDuties = async () => {
      setLoading(true);
      try {
        const response = await getAcademicExperienceById(userId,"Academic Duties",token);
        setDuties(response);
      } catch (error) {
        console.error("Failed to fetch duties:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDuties();
  }, [userId, token]);

  if (loading) {
    return (
      <Stack alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  const sliceTre = (string) => {
    return string?.split("-")[1]?.toLowerCase() || "";
  };

  return (
    <Stack alignItems="start" sx={{ width: '100%', p: 2 }}>
      {duties.length === 0 ? (
        <Typography variant="h6" color="text.secondary" align="center">
          No academic duties have been added yet.
        </Typography>
      ) : (
        <Timeline
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.3,
            },
          }}
        >
          {duties.map((duty, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: index * 0.3 }}
            >
              <TimelineItem>
                <TimelineOppositeContent color="text.secondary" sx={{ typography: 'body2', lineHeight: 1.5 }}>
                  {`${duty.startDate} - ${duty.endDate == null ? "present" : duty.endDate}`}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary" sx={{ transform: 'scale(1.2)' }} />
                  {index < duties.length - 1 && <TimelineConnector sx={{ backgroundColor: '#1976d2' }} />}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant="h5" component="span" sx={{ fontWeight: 600 }} color="primary">
                    {duty.duty}
                  </Typography>
                  <Typography variant="h6">
                    {duty.institution}{duty.department && ", Department of " + duty.department}
                  </Typography>
                  <Stack direction="row" alignItems="center">
                    <img
                      style={{ marginRight: 6 }}
                      loading="lazy"
                      srcSet={`https://flagcdn.com/w40/${sliceTre(duty.country)}.png 2x`}
                      src={`https://flagcdn.com/w20/${sliceTre(duty.country)}.png`}
                      alt=""
                    />
                    <Typography variant="body2" color="text.secondary">
                      {duty.country}
                    </Typography>
                  </Stack>
                </TimelineContent>
              </TimelineItem>
            </motion.div>
          ))}
        </Timeline>
      )}
    </Stack>
  );
};

export default AcademicDutiesPage;
