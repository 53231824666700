import React, { useEffect, useState } from 'react';
import { Stack, Typography, CircularProgress, IconButton } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineOppositeContent, timelineOppositeContentClasses, TimelineContent } from '@mui/lab';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../auth/AuthProvider';
import { getAcademicExperienceById } from '../../services/academicExperienceServices';

const StudentCompetitionPage = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userId } = useParams();
  const { token } = useUserContext();

  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      try {
        const response = await getAcademicExperienceById(userId, "Student Competition", token); // Adjust the API function to fetch student projects
        setProjects(response);
      } catch (error) {
        console.error("Failed to fetch student competitions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [userId, token]);

  if (loading) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ height: '100vh' }}>
        <CircularProgress />
      </Stack>
    );
  }
  const sliceTre = (string) => {
    return string?.split("-")[1]?.toLowerCase() || "";
  };

  return (
    <Stack alignItems="start" sx={{ width: '100%', p: 2 }}>
      {projects.length === 0 ? (
        <Typography variant="h6" color="text.secondary" align="center">
          No student competitions have been added yet.
        </Typography>
      ) : (
        <Timeline
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.3,
            },
          }}
        >
          {projects.map((project, index) => (
            <TimelineItem key={project.id}>
              <TimelineOppositeContent color="text.secondary" sx={{ typography: 'body2', lineHeight: 1.5 }}>
                {project.year}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" sx={{ transform: 'scale(1.2)' }} />
                {index < projects.length - 1 && <TimelineConnector sx={{ backgroundColor: '#1976d2' }} />}
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant='h5' component="span" sx={{ fontWeight: 600 }} color="primary">
                  {project.competitionName}
                </Typography>
                <Typography variant="h6">
                  {project.advisors?.map((advisor, index) => (
                    `${advisor}${index < project.advisors.length - 1 ? ', ' : ''} `
                  ))}
                  ({project.year}). {project.university}{project.department && ", Department of " + project.department}
                </Typography>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Stack direction="row" alignItems="center">
                    <img
                      style={{ marginRight: 6 }}
                      loading="lazy"
                      srcSet={`https://flagcdn.com/w40/${sliceTre(project.country)}.png 2x`}
                      src={`https://flagcdn.com/w20/${sliceTre(project.country)}.png`}
                      alt=""
                    />
                    <Typography variant="body2" color="text.secondary">
                      {project.country}
                    </Typography>
                  </Stack>
                </Stack>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      )}
    </Stack>
  );
};

export default StudentCompetitionPage;
