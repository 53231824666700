import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Container, Grid, IconButton, Paper, Stack, TextField, Button, Typography, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { TimelineOppositeContent, timelineOppositeContentClasses } from '@mui/lab';
import { Edit, Delete, ArrowBack, Add, Language } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getAcademicExperienceForCurrentUser, updateDirectedThesis, deleteAcademicExperience, createDirectedThesis } from '../services/academicExperienceServices';
import { useUserContext } from '../auth/AuthProvider';
import CountrySelect from '../components/CountrySelect';
import { motion } from "framer-motion";
import UniversitySearch from '../components/profile/UniversitySearch';
import DepartmentSearch from '../components/profile/DepartmentSearch';
import FieldOfStudySearch from '../components/profile/FieldOfStudySearch';
import PositionSearch from '../components/profile/PositionSearch';
const languages = [
  "Arabic", "Abkhaz", "Afar", "Albanian", "Alsatian", "Amharic", "Armenian",
  "Assamese", "Aymara", "Azari", "Azerbaijani", "Azerbaijani Turkic",
  "Bahasa Indonesia", "Bahasa Indonesian", "Balochi", "Bangla", "Bantu",
  "Bapounou", "Basque", "Bateke", "Belorussian", "Belorussian", "Bislama",
  "Bosnian", "Breton", "Bubi", "Bulgarian", "Cantonese", "Castilian",
  "Castilian Spanish", "Catalan", "Catalán", "Chinese", "Corsican",
  "Creole", "Criolo", "Criuolo", "Croatian", "Czech", "Danish", "Dari",
  "Dhivehi", "Dutch", "Dzongkha", "English", "Eschira", "Estonian",
  "Faeroese", "Fang", "Farsi", "Fijian", "Filipino", "Finnish", "Flemish",
  "Fon", "French", "French patois", "Galole", "Garifuna (Carib)",
  "Georgian", "German", "Greek", "Greenlandic", "Guaragigna",
  "Hansa, Swahili", "Hassaniya Arabic", "Hazaragi", "Hebrew", "Hindi",
  "Hindustani", "Hungarian", "Ibo", "Icelandic", "Irish Gaelic", "Italian",
  "Japanese", "Kazak", "Kekchi", "Kemak", "Khmer", "Kikongo", "Kingwana",
  "Kinyarwanda", "Kirundi", "Korean", "Kurdish", "Kyrgyz", "Lao", "Latin",
  "Latvian", "Leboa", "Lingala", "Luxermbourgish", "Macedonian", "Magyar",
  "Malagasy", "Malay", "Maltese", "Mambae", "Mandarin", "Mandingo",
  "Marshallese", "Mayan", "Melanesian", "Moldovan", "Mongolian",
  "Monokutuba", "Myene", "Nahua", "Nauruan", "Nepalese dialects", "Nepali",
  "Orominga", "Palau", "Pashtu", "Patois", "Polish", "Portuguese",
  "Provençal", "Punjabi", "Quechua", "Rohingya", "Romanian", "Russian",
  "Samoan", "Sangho", "Serbian", "Setswana", "Shikomoro", "Sinhala",
  "Slovak", "Slovene", "Slovenian", "Somali", "Spanish", "Swahili",
  "Swedish", "Tagalog", "Tajik", "Tetum", "Thai", "The Myanmar language",
  "Tibetan", "Tigre and Kunama", "Tigrigna", "Tongan", "Tshiluba",
  "Turkish", "Turkmen", "Tuvaluan", "Ukrainian", "Urdu", "Uzbek",
  "Vietnamese", "Yoruba"
];
const degrees = ["Bachelor Thesis", "Master Thesis", "Doctoral Thesis", "Post Doctoratal Thesis"];
const DirectedThesisPage = () => {
  const navigate = useNavigate();
  const { token } = useUserContext();
  const [theses, setTheses] = useState([]);
  const [formValues, setFormValues] = useState({
    thesisDegree: '',     // New field for thesis degree
    position: '',         // New field for position
    title: '',            // New field for title
    university: '',       // New field for university
    department: '',       // New field for department
    year: '',             // New field for year
    language: '', 
    name:'',        // New field for language
  });
  const [isPresent, setIsPresent] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    const fetchTheses = async () => {
      try {
        const data = await getAcademicExperienceForCurrentUser("Directed Thesis", token);
        console.log("Directed Thesis Data:", data);
        setTheses(data);
      } catch (error) {
        console.error('Failed to fetch theses:', error);
      }
    };

    fetchTheses();
  }, [token]);

  useEffect(() => {
    const { thesisDegree, position, title, university, department, year, language } = formValues;
    setValid(thesisDegree && position && title && university && department && year && language);
  }, [formValues]);

  const handleSelectUniversity = (newValue) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      university: newValue,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleAddThesis = () => {
    setIsFormVisible(true);
    setEditingIndex(null);
    resetForm();
  };

  const resetForm = () => {
    setFormValues({
      thesisDegree: '',
      position: '',
      title: '',
      university: '',
      department: '',
      year: '',
      language: '',
      name:''
    });
    setIsPresent(false);
  };

  const handleDeleteThesis = async (index) => {
    const thesisId = theses[index].id;
    try {
      await deleteAcademicExperience(thesisId, token);
      setTheses((prevTheses) => prevTheses.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Failed to delete thesis:", error);
    }
  };

  const handleSave = async () => {
    try {
      if (editingIndex !== null) {
        const thesisId = theses[editingIndex].id;
        await updateDirectedThesis(thesisId, formValues, token);
        const updatedTheses = [...theses];
        updatedTheses[editingIndex] = { ...formValues, id: thesisId };
        setTheses(updatedTheses);
      } else {
        const newThesis = await createDirectedThesis(formValues, token);
        setTheses((prevTheses) => [...prevTheses, newThesis]);
      }
      setIsFormVisible(false);
      resetForm();
    } catch (error) {
      console.error(editingIndex !== null ? 'Failed to update thesis:' : 'Failed to create thesis:', error);
    }
  };

  const handleEditThesis = (index) => {
    if (index >= 0 && index < theses.length) {
      setEditingIndex(index);
      const selectedThesis = theses[index];
      setFormValues(selectedThesis);
      setIsFormVisible(true);
    }
  };

  const handleCancelEdit = () => {
    setIsFormVisible(false);
    setEditingIndex(null);
    resetForm();
  };
  function formatPostDoctorateKey(key) {
    // Eğer key null ise boş bir string döndür
    if (key === null) {
      return '';
    }

    // Büyük harfleri küçült ve alt çizgileri boşlukla değiştir
    let formatted = key.toLowerCase().replace(/_/g, ' ');

    // Her kelimenin ilk harfini büyük yap
    formatted = formatted.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return formatted;
  }
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 4 }}>
        <Button startIcon={<ArrowBack />} onClick={() => navigate(-1)} sx={{ mb: 4 }}>
          Back
        </Button>
        <Typography variant="h5" fontWeight="bold" color="primary" gutterBottom>
          Directed Theses
        </Typography>
        <Timeline sx={{ [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.3 } }}>
          {theses.map((thesis, index) => (
            <motion.div
              key={thesis.id}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: index * 0.3 }}
            >
              <TimelineItem>
                <TimelineOppositeContent color="text.secondary" sx={{ typography: 'body2', lineHeight: 1.5 }}>
                  {thesis.year}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary" sx={{ transform: 'scale(1.2)' }} />
                  {index < theses.length - 1 && <TimelineConnector sx={{ backgroundColor: '#1976d2' }} />}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant='h5' component="span" sx={{ fontWeight: 600 }} color="primary">
                    {thesis.thesisDegree} {thesis.position} ({thesis.year})
                  </Typography>
                  <Typography variant="h6">
                    {thesis.title}. <b>{thesis.thesisDegree} by {thesis.name}</b>. {thesis.university}, {`Department of ${thesis.department}`}
                  </Typography>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Language />
                    <Typography variant="body1" color="gray">
                      {thesis.language}
                    </Typography>
                  </Stack>
                  <div style={{ display: 'flex', marginTop: 8 }}>
                    <IconButton size="small" color="primary" onClick={() => handleEditThesis(index)}>
                      <Edit fontSize="small" />
                    </IconButton>
                    <IconButton size="small" color="secondary" onClick={() => handleDeleteThesis(index)}>
                      <Delete color='error' fontSize="small" />
                    </IconButton>
                  </div>
                </TimelineContent>
              </TimelineItem>
            </motion.div>
          ))}
        </Timeline>

        {isFormVisible && (
          <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary" sx={{ mt: 4 }}>
                  {editingIndex !== null ? 'Edit Thesis' : 'Add New Thesis'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  size='small'
                  fullWidth
                  label="Title"
                  name="title"
                  value={formValues.title}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  size='small'
                  fullWidth
                  label="Student Name"
                  name="name"
                  value={formValues.name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size='small'>
                  <InputLabel>Degree</InputLabel>
                  <Select
                    size='small'
                    name="thesisDegree"
                    value={formValues.thesisDegree}
                    onChange={handleInputChange}
                  >
                    {degrees.map((degree, index) => (
                      <MenuItem key={index} value={degree}>{degree}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size='small'>
                  <InputLabel>Position</InputLabel>
                  <Select
                    size='small'
                    name="position"
                    value={formValues.position}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="Advisor">Advisor</MenuItem>
                    <MenuItem value="Co-advisor">Co-advisor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <UniversitySearch
                  onSelectUniversity={handleSelectUniversity}
                  selectedUniversity={formValues.university}
                  label="University"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DepartmentSearch
                  onSelectDepartment={(newValue) =>
                    handleInputChange({
                      target: {
                        name: 'department',
                        value: newValue,
                      },
                    })
                  }
                  selectedDepartment={formValues.department}
                  label="Department"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size='small'
                  fullWidth
                  label="Year"
                  name="year"
                  type="number"
                  value={formValues.year}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Language</InputLabel>
                  <Select
                    label="Language"
                    name='language'
                    value={formValues.language}
                    onChange={handleInputChange}
                  >
                    {languages.map((langue, index) => (
                      <MenuItem key={index} value={langue}>
                        {langue}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleSave} disabled={!valid}>
                  {editingIndex !== null ? 'Update Thesis' : 'Add Thesis'}
                </Button>
                <Button variant="outlined" onClick={handleCancelEdit} sx={{ ml: 2 }}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
        <Button variant="outlined" color="primary" startIcon={<Add />} onClick={handleAddThesis} sx={{ mt: 4 }}>
          Add New Thesis
        </Button>
      </Paper>
    </Container >
  );
};

export default DirectedThesisPage;
